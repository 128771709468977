.dispo-img {
  height: 400px;
  width: 700px;
  object-fit: cover;
}

.dispo-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
  margin-top: 20px;
}

.dispo-tarif-header {
  flex-direction: horizontal;
}

.dispo-tarif {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 736px;
  margin: 0 auto 20px;
}

.season-header {
  color: red;
}

.taxe-sejour {
  margin-top: 32px;
}

@media screen and (max-width:780px) {
  .dispo-img {
    height: auto;
    width: 100%;
  }
  .dispo-img-div {
    margin: 24px;
  }
}
