.activite-info {
  max-width: 600px;
  margin: 20px auto;
}

.activite-cards {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(3, 464px);
  max-width: 1200px;
  margin: 0 auto;
}

.activite-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.card-content {
  padding: 24px;
}

.card-content-title {
  padding: 6px 0 26px;
}

.activite-cards-mobile {
  display: none;
}

.activite-cards-ipad {
  display: none;
}

@media screen and (max-width: 780px) {
  .activite-cards-ipad {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(6, 464px);
  }
  .activite-cards {
    display: none;
  }
  .activite-cards-mobile {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .activite-cards {
    display: none;
  }
  .activite-cards-ipad {
    display: none;
  }
  .activite-cards-mobile {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(12, 450px);
  }
}
