._2rGHACUOk7O33LETXkJFNM {
  display: none !important;
}

._3T2QcXpoCjBkZDr7ZQsU2x+._3T2QcXpoCjBkZDr7ZQsU2x {
  display: none !important;
}

._1H30v4mjCOueb3KU3usjjl {
  padding-left: 8px;
}

._3qF059Sfa67iYyTWuDqNHN {
  font-size: 20px !important;
}

._3DvA0XpDETXi0STdkH17YZ {
  box-shadow: 0 0 8px rgba(45, 45, 45, .2) !important;
  border: none !important;
  background: rgb(250,250,250) !important;
}

.appMountPoint-asideBooking {
  width: 80%;
  margin: 20px auto;
}
