@import "navbar";
@import "footer";
@import "home";
@import "carousel";
@import "description";
@import "disponibilite";
@import "emplacement";
@import "activite";
@import "link";
@import "form";
@import "widget";
@import "loadHome";

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h3 {
  font-size: 28px;
  padding: 18px;
}

body {
  font-family: 'Quicksand', sans-serif;
}

.center {
  text-align: center;
}

.p-12 {
  padding: 12px;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center
}

.m-5 {
  margin: 50px;
}

.underline {
  text-decoration: underline;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  flex-shrink: 0;
  height: 82px;
}

.main-content {
  flex: 1 0 auto;
}
