.carousel {
  position: relative;
  height: 380px;
  width: 80%;
  overflow: hidden;
  border-radius: 4px;
}

.chevron-left {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 40px;
  transition: ease .4s;
  &:hover {
    transform: scale(1.4);
  }
}

.chevron-right {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 40px;
  transition: ease .4s;
  &:hover {
    transform: scale(1.4);
  }
}

.carousel-img {
  object-fit: cover;
  display: none;
  width: 100%;
  height: 100%;
}

.active {
  display: block;
}

@media screen and (max-width: 780px) {
  .chevron-right {
    &:hover {
      transform: scale(1);
    }
  }
  .chevron-left {
    &:hover {
      transform: scale(1);
    }
  }
}
