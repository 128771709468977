.home-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 74px auto 48px;
  padding: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, .2);
  border-radius: 12px;
}

.home-text {
  width: 30%;
  height: 478px;
}

.allPhotos {
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-photo {
  width: 46%;
  height: 478px;
  object-fit: cover;
  border-radius: 4px;
}

.numero-activity {
  color: red;
  font-size: 78px;
  font-weight: bold;
  padding: 12px;
}

.home-button {
  text-decoration: none;
  color: black;
  border: 2px solid black;
  padding: 6px 12px;
  border-radius: 12px;
  transition: ease .3s;
  &:hover {
    color: white;
    background-color: black;
    text-decoration: none;
  }
}

.home-map {
  margin-bottom: 48px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, .2);
  padding: 0;
  height: 450px;
  .map-container {
    width: 100%;
    height: 100%;
  }
}


.ami-locataire {
  max-width: 650px;
}

.home-img {
  width: 100%;
  height: 89vh;
  object-fit: cover;
}

@media screen and (max-width: 780px) {
  .home-info {
    flex-direction: column;
  }
  .home-text {
    width: 80%;
    height: auto;
  }
  .allPhotos {
    width: 80%;
    flex-direction: column;
  }
  .home-photo {
    width: 100%;
    height: auto;
    padding: 12px;
    border-radius: 16px;
  }
}
