.form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  align-items: center;
  margin: 12px auto;
}

.form-text {
  width: 360px;
  margin: 6px auto;
}

.form-text-area {
  width: 360px;
  margin: 6px auto;
}

.form-message-sent {
  color: green;
  margin-top: 6px;
}
