.onload {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background: lightsteelblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;
    box-shadow: 0px 5px rgb(72, 98, 134), 0px 20px rgb(100, 128, 164);
    animation: move 1s ease 3s forwards;
}

.overflow-load {
    position: absolute;
    bottom: 18rem;
    overflow: hidden;
    .loading-bar {
        border-bottom: 4px solid lightgray;
        width: 300px;
        height: 20px;
        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            right: 0px;
            left: 0px;
            height: 4px;
            background-color: #476286;
            transform: translateX(-100%);
            animation: load 2s linear .5s forwards;
        }
    }
}

@keyframes move {
    from{transform: translateY(0)}
    to{transform: translateY(-120%);}
}

@keyframes load {
    from{transform: translateX(-100%);}
    to{transform: translateX(0%);}
}