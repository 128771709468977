.emplacement-img {
  height: 650px;
}

.emplacement-content {
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 12px;
  margin-left: 48px;
}

.emplacement-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

.map-emplacement {
  height: 100%;
  width: 100%;
  .map-container-emplacement {
    height: 100%;
    width: 100%;
  }
  .mapboxgl-map {
    border-radius: 12px;
  }
}

@media screen and (max-width: 780px) {
  .emplacement-div {
    margin: 34px;
    flex-direction: column;
  }
  .emplacement-content {
    margin-left: 0;
  }
  .map-emplacement {
    height: 70%;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .emplacement-img {
    width: 100%;
    height: auto;
  }
}
