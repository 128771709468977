.link {
  text-align: center;
  margin-top: 168px;
}

.usefull-link {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 56px;
  text-decoration: underline;
}

.link-card {
  background-color: rgba(255,255,255, .4);
  margin: auto;
  width: 204px;
  height: 270px;
}

.link-title {
  font-size: 80px;
  font-weight: lighter;
}

.link-btn {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

.flex-link {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 40px 0;
}

.link-card-content {
  width: 204px;
  text-align: center;
  display: inline-block;
  margin: 12px;
  transition: ease .3s;
  &:hover {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 780px) {
  .link {
    margin-top: 106px;
  }
  .usefull-link {
    font-size: 34px;
  }
  .link-card-content {
    &:hover {
      transform: scale(1);
    }
  }

}
